import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { StaticImage } from "gatsby-plugin-image";

// services
import { sendToLSQ } from "../../services/LeadSquared";
import { sendToCT } from "../../services/Clevertap";

// constants
import constants from "../../constants";

// component
import DropDown from "../DropDown/DropDown";

// css
import * as styles from "./Assessment.module.css";

const AssessmentForm = props => {
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [showLanguageError, setShowLanguageError] = useState("none");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const handleLanguageSelection = value => {
    if (value.length > 0) {
      setSelectedLanguage(value);
      setShowLanguageError("none");
    } else {
      setShowLanguageError("block");
    }
  };

  const onSubmit = async data => {
    try {
      // leadSquared params
      let leadSquaredParams = {
        FirstName: data.name,
        Phone: data.phone,
        EmailAddress: data.email,
        mx_Website_CTA: "metabolism_assessment_quiz",
        mx_Latest_Source: props.utm_source || "",
        mx_quiz_weight: props.userQuizResult.weight,
        mx_quiz_hba1c: props.userQuizResult.sugarLevel,
        mx_Height: props.userQuizResult.height,
        mx_Language: selectedLanguage[0].display,
        mx_quiz_medicines: props.userQuizResult.medicationTypeOption,
        mx_Pack_Amount: props.userQuizResult.medicationOption,
        mx_How_often_you_smoke: props.userQuizResult.smokeOption,
        mx_How_often_you_consume_alcohol: props.userQuizResult.drinkOption,
        mx_capture_the_metabolic_score: props.finalQuizScore,
        mx_capture_BMI: props.bmiScore,
      };

      data.language = selectedLanguage;

      if (selectedLanguage.length > 0) props.handleUserDetails(data);
      await sendToLSQ(leadSquaredParams);
      sendToCT("metabolism_assessment_successful", data);
    } catch (error) {
      console.error("Metabolism Assessment quiz lsq call error", error);
    }
  };

  // Handle to end quiz
  const handleEndQuiz = () => {
    props.handleNextStep(0);
    window.location.reload();
  };

  return (
    <div className={`bgDarkBlue ${styles.conHeightForm}`}>
      <div className={`container twinContainer ${styles.assessQuizForm__cont}`}>
        <div
          className={`d-flex align-item-center ${styles.assessQuizForm__colHeader}`}
        >
          <div className={styles.assessQuizForm__colHead__txt}>
            <p>Self Assessment</p>
          </div>
          <div className={styles.assessQuizForm__colHead__crossIcn}>
            <span
              onClick={handleEndQuiz}
              role={"presentation"}
              className="smallIcon cursorPointer icon-cross"
            />
          </div>
        </div>
        <div className={styles.assessQuizForm__col}>
          {/* Heading */}
          <div className={styles.assessQuizForm__colHead__hdng}>
            <h3>Fill in the details below to know your result</h3>
          </div>
          <div className={styles.assessQuizForm__colHead__form}>
            <form
              name="bookTrial"
              onSubmit={handleSubmit(onSubmit)}
              className={`mt-3 row`}
            >
              <div className={`col-md-6`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Full Name*"
                    id="name"
                    type="text"
                    {...register("name", {
                      required: "Please enter your name",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid name",
                      },
                      maxLength: { value: 50, message: "Name is too long" },
                    })}
                  />
                  {errors.name && (
                    <span className="invalid-feedback">
                      {errors.name.message}
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-md-6`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <input
                    className={`form-control letterSp1 ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                    placeholder="WhatsApp Number*"
                    id="phone"
                    type="text"
                    inputMode="numeric"
                    maxLength="10"
                    {...register("phone", {
                      required: "Please enter your whatsapp number",
                      pattern: {
                        value:
                          /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid phone number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Invalid phone number",
                      },
                    })}
                  />
                  {errors.phone && (
                    <span className="invalid-feedback">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>

              <div className={`col-md-6`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Email*"
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Please enter your email id",
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="invalid-feedback">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>

              <div className={`col-md-6`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <input
                    className={
                      errors.city ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="City*"
                    id="city"
                    type="text"
                    {...register("city", {
                      required: "Please enter your city",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid city",
                      },
                      maxLength: { value: 20, message: "City is too long" },
                    })}
                  />
                  {errors.city && (
                    <span className="invalid-feedback">
                      {errors.city.message}
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-md-6`}>
                <div
                  className={`assessquiz__langModal__dropdown ${styles.langModal__dropdown}`}
                >
                  <DropDown
                    displayValue="display"
                    onSelect={handleLanguageSelection}
                    onRemove={handleLanguageSelection}
                    options={constants.QUIZ_LANGUAGES}
                    showCheckbox={true}
                    selectionLimit={1}
                    placeholder="Please select a language"
                    hidePlaceholder={true}
                    showArrow={true}
                    customCloseIcon={
                      <StaticImage
                        src="../../images/bookPack/cross_2.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          width: "14px",
                          height: "14px",
                          marginLeft: "4px",
                        }}
                      />
                    }
                    customArrow={
                      <StaticImage
                        src="../../images/bookPack/arrow_dropdown.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          marginTop: "9px",
                        }}
                      />
                    }
                  />

                  {/* To show language error */}
                  {selectedLanguage.length === 0 && (
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "0.875em",
                        display: showLanguageError,
                      }}
                    >
                      <p style={{ marginTop: "22px" }}>
                        Please select a language
                      </p>
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-md-6`}></div>
              <div
                className={`col-12 col-md-6 mt-2 cursorPointer ${styles.assessQuizForm__colHead__cta}`}
              >
                <button
                  type="submit"
                  className={`gradientBtnWithArrow w-100 pt-2 pb-2 ${styles.submitBtn}`}
                  onClick={() => setShowLanguageError("block")}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentForm;

import React, { useState, useEffect } from "react";

// constants
import constants from "../../constants";

//component
import DropDown from "../DropDown/DropdownQuiz";

// css
import * as styles from "./Assessment.module.css";

// images
import { StaticImage } from "gatsby-plugin-image";

const AssessmentQuiz = props => {
  const [step, setStep] = useState(0);
  const [optionSelect, setOptionSelect] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedHeight, setSelectedHeight] = useState([]);
  const [weightValue, setWeightValue] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(constants.METABOLIC_ASSESSMENT_QUESTIONS[step].options);
  }, [step]);

  let questions = constants.METABOLIC_ASSESSMENT_QUESTIONS;

  // Handle height selection
  const handleHeightSelection = value => {
    if (value.length > 0) {
      setIsChecked(true);
      setSelectedHeight(value);
    } else {
      setIsChecked(false);
    }
  };

  // Handle Weight Selection
  const handleWeightValue = e => {
    let inputValue = e.target.value;

    // Use a regular expression to check if the input contains only numbers and accepts only 3 digits
    if (!/^\d*$/.test(inputValue)) {
      // If the input contains non-numeric characters, clear the input
      e.target.value = "";
      setIsChecked(false);
    } else if (inputValue.length > 3) {
      // If the input has more than 3 digits, limit it to the first 3 digits
      e.target.value = inputValue.slice(0, 3);
      const tempInputValue = e.target.value;
      inputValue = tempInputValue;
    }

    if (!inputValue) {
      setIsChecked(false);
    } else if (!/^\d*$/.test(inputValue)) {
      setIsChecked(false);
    } else {
      setWeightValue(inputValue);
      setIsChecked(true);
    }
  };

  // Handle checkbox selection of options
  const handleCheckboxChange = (optionId, option, event) => {
    const updatedOptions = options.map(option =>
      option.id === optionId
        ? { ...option, selected: !option.selected }
        : { ...option, selected: false }
    );
    setOptions(updatedOptions);
    setOptionSelect(option);

    // Enable the next button only when checkbox is checked
    if (event.target.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  // Handle Previous button click
  const handlePreviousButton = () => {
    if (step === 0) {
      props.handleNextStep(0);
    } else {
      setIsChecked(true);
      setStep(step - 1);

      const updatedOptions = options.map(option =>
        option.id === optionSelect.id
          ? { ...option, selected: !option.selected }
          : { ...option, selected: false }
      );
      setOptions(updatedOptions);

      // Disable the next button only when user lands on weight ques i.e From Q3 to Q2(weight ques)
      if (step === 2) setIsChecked(false);
    }
  };

  // Handle Next button click
  const handleNextButton = () => {
    setIsChecked(false);

    const activeQuestion = questions[step];

    activeQuestion.options.map(option => {
      if (option.id === optionSelect.id) {
        option.selected = true;
      } else {
        option.selected = false;
      }
      return option;
    });

    let nextStep = step;

    if (nextStep + 1 < questions.length) {
      nextStep = step + 1;

      questions[nextStep].options.map(option => {
        if (option.id === optionSelect.id) {
          option.selected = false;
        } else {
          option.selected = false;
        }
        return option;
      });
    } else {
      let result = {};
      for (let question of questions) {
        const found = question.options.find(item => item.selected === true);

        if (question.id === 1) {
          result.height = Number(selectedHeight[0].name);
        } else if (question.id === 2) {
          result.weight = Number(weightValue);
        } else if (question.id === 3) {
          result.sugarLevel = Number(found.score);
          result.sugarLevelOption = found.option;
        } else if (question.id === 4) {
          result.medicationType = Number(found.score);
          result.medicationTypeOption = found.option;
        } else if (question.id === 5) {
          result.medication = Number(found.score);
          result.medicationOption = found.option;
        } else if (question.id === 6) {
          result.smoke = Number(found.score);
          result.smokeOption = found.option;
        } else if (question.id === 7) {
          result.drink = Number(found.score);
          result.drinkOption = found.option;
        }
      }

      // console.log("results..", result);
      setTimeout(() => {
        props.handleCalculateQuizScore(result);
      }, 1000);
      return;
    }

    setTimeout(() => {
      setStep(nextStep);
    }, 400);
  };

  // Handle to end quiz
  const handleEndQuiz = () => {
    props.handleNextStep(0);
    window.location.reload();
  };

  return (
    <div className={`bgDarkBlue ${styles.conHeightQuiz}`}>
      <div className={`container twinContainer ${styles.assessQuiz__quizCont}`}>
        {/* Header */}
        <div className={`row ${styles.assessQuiz__quizCont__row}`}>
          <div className="col-12">
            <div
              className={`d-flex align-items-center ${styles.assessQuiz__quizCont__rowDiv}`}
            >
              <p>Self Assessment</p>
              <span
                onClick={handleEndQuiz}
                role={"presentation"}
                className="smallIcon cursorPointer icon-cross"
              />
            </div>
          </div>
        </div>
        {/* Question */}
        <div className={`row ${styles.assessQuiz__quizCont__quesRow}`}>
          <div className={`col-12`}>
            <div className={`row ${styles.assessQuiz__quizCont__quesRowQue}`}>
              <div
                className={`col-10 ${styles.assessQuiz__quizCont__quesRowQues}`}
              >
                <p>{questions[step]["question"]}</p>
              </div>
              <div
                className={`col-2 ${styles.assessQuiz__quizCont__quesRowSteps}`}
              >
                <p>
                  {step + 1} out of {questions.length}
                </p>
              </div>
            </div>

            {/* To Show Height Question */}
            {questions[step]["id"] === 1 ? (
              <div className={`row`}>
                <div
                  className={`col-10 ${styles.assessQuiz__quizCont__hghtDropdwn}`}
                >
                  <DropDown
                    displayValue="display"
                    onSelect={handleHeightSelection}
                    onRemove={handleHeightSelection}
                    options={constants.QUIZ_HEIGHT}
                    showCheckbox={true}
                    selectionLimit={1}
                    placeholder="Select your height"
                    hidePlaceholder={true}
                    showArrow={true}
                    selectedValues={selectedHeight}
                    customCloseIcon={
                      <StaticImage
                        src="../../images/bookPack/cross_2.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          width: "14px",
                          height: "14px",
                          marginLeft: "4px",
                        }}
                      />
                    }
                    customArrow={
                      <StaticImage
                        src="../../images/bookPack/arrow_dropdown.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          marginTop: "9px",
                        }}
                      />
                    }
                  />
                </div>
                <div className={`col-2`}></div>
              </div>
            ) : (
              <div>
                {/* To Show Weight UI */}
                {questions[step]["id"] === 2 ? (
                  <div className={`row`}>
                    <div
                      className={`col-12 ${styles.assessQuiz__quizCont__wghtIp}`}
                    >
                      <input
                        className={`form-control`}
                        placeholder="Please enter your weight in Kgs"
                        id="weight"
                        type="text"
                        maxLength={3}
                        inputMode="numeric"
                        onChange={handleWeightValue}
                      />
                    </div>
                  </div>
                ) : (
                  // To Show Rest Questions UI
                  <div className={`row`}>
                    <div className={`col-10`}>
                      {options.map((option, index) => {
                        return (
                          <div
                            className={`${styles.assessQuiz__quizCont__checkbox}`}
                            key={index}
                          >
                            <input
                              type="checkbox"
                              checked={option.selected}
                              className={option.selected ? "icon-yes" : null}
                              onChange={event =>
                                handleCheckboxChange(option.id, option, event)
                              }
                            />
                            <label htmlFor={option.option}>
                              {option.option}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className={`col-2`}></div>
                  </div>
                )}
              </div>
            )}
            <div className={`row`}>
              <div className="col-1"></div>
              <div className="col-10">
                <div className={styles.assessQuiz__quizCont__ctaDiv}>
                  <button
                    className={styles.assessQuiz__quizCont__prevCta}
                    onClick={handlePreviousButton}
                  >
                    Previous
                  </button>
                  <button
                    className={
                      isChecked
                        ? styles.assessQuiz__quizCont__nextCta
                        : styles.assessQuiz__quizCont__nextCtaDis
                    }
                    onClick={handleNextButton}
                    disabled={!isChecked}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentQuiz;

import React, { Component } from "react";
import queryString from "query-string";
import { navigate } from "gatsby";

// seo
import Seo from "../components/Seo";

// services
import { sendToLSQ } from "../services/LeadSquared";

// utils
import {
  saveUTM,
  splitName,
  convertUstDateTimeFormat,
  convertUstDateTimeFormat2,
} from "../utils";

// hooks
import { useQuizQuery } from "../hooks/useQuizQuery";

// api actions
import {
  doValidateAvailableSlotsAcuity,
  doValidateBookSlotsAcuity,
} from "../actions/metabolismQuizActions";

// components
import HeaderWithLogo from "../components/Header/HeaderWithLogo";
import AssessmentHome from "../components/MetabolismAssessment/AssessmentHome";
import AssessmentQuiz from "../components/MetabolismAssessment/AssessmentQuiz.js";
import AssessmentForm from "../components/MetabolismAssessment/AssessmentForm";
import AssessmentResult from "../components/MetabolismAssessment/AssessmentResult";

class MetabolismAssessmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      userQuizResult: {},
      finalQuizScore: 0,
      userDetail: {},
      firstNameUser: "",
      lastNameUser: "",
      currentMonth: 0,
      currentYear: 0,
      bmiScore: 0,
      acuityData: {},
      selectedCalendarId: 0,
      loading: false,
    };
  }

  componentDidMount() {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    this.setState({
      currentMonth: month + 1,
      currentYear: year,
    });

    // Save UTM Params to Local Storage
    if (this.props.location.search) {
      saveUTM(this.props.location.search);
    }

    // Fetch acuity appointment ids and calendar ids using hooks
    const quizPayloadData = this.props.metabolismQuiz.quiz_payload[0];
    this.setState({ acuityData: quizPayloadData });
  }

  // Move user to Next Screen
  handleNextStep = nextStep => {
    this.setState({ step: nextStep });
  };

  // to calculate the final score of the quiz
  handleCalculateQuizScore = async values => {
    if (values) {
      this.setState({ userQuizResult: values });

      // calculate BMI score of the user
      // Height is in cm. so converting height into meter
      const heightInMeter = values.height / 100;
      // BMI formulae = weight (kg) / [height (m)]2
      const bmiScore = values.weight / (heightInMeter * heightInMeter);
      this.setState({ bmiScore: bmiScore });

      let bmiScoreRangeValue;
      if (bmiScore >= 18 && bmiScore < 23) {
        bmiScoreRangeValue = 11;
      } else if (bmiScore >= 23 && bmiScore < 25) {
        bmiScoreRangeValue = 9;
      } else if (bmiScore >= 25 && bmiScore < 30) {
        bmiScoreRangeValue = 7;
      } else if (bmiScore >= 30 && bmiScore < 35) {
        bmiScoreRangeValue = 4;
      } else {
        bmiScoreRangeValue = 2;
      }

      const calculateFinalScore = Number(
        values.drink +
          values.medication +
          values.medicationType +
          values.smoke +
          values.sugarLevel +
          bmiScoreRangeValue
      );

      this.setState({ finalQuizScore: calculateFinalScore });
      // move to form screen
      this.handleNextStep(2);
    }
  };

  // capture user details from the form
  handleUserDetails = value => {
    // util function -> to split the name
    const { firstName, lastName } = splitName(value.name);

    this.setState({
      userDetail: value,
      firstNameUser: firstName,
      lastNameUser: lastName,
    });

    // Acuity Function Call
    // this.handleAvailableAcuitySlots();

    // move to result screen
    this.handleNextStep(3);
  };

  // Acuity Function:- To Fetch Available Calendar Schedule/Slots For Appointments
  handleAvailableAcuitySlots = async () => {
    try {
      const { userDetail, acuityData } = this.state;

      // Available Slots -> Hindi Language
      if (userDetail.language[0].display === "Hindi") {
        const response = await doValidateAvailableSlotsAcuity(
          Number(acuityData.appointmentTypeID),
          Number(acuityData.hindiCalendarID)
        );
        // Move to result screen
        if(response.data) this.handleNextStep(4);

        this.setState({
          calendarData: response.data.slice(0, 3), // Pick first 3 calendar available slots
          selectedCalendarId: Number(acuityData.hindiCalendarID),
        });
      }
      // Available Slots -> English Language
      if (userDetail.language[0].display === "English") {
        const response = await doValidateAvailableSlotsAcuity(
          Number(acuityData.appointmentTypeID),
          Number(acuityData.englishCalendarID)
        );
        // Move to result screen
        if(response.data) this.handleNextStep(4);

        this.setState({
          calendarData: response.data.slice(0, 3), // Pick first 3 calendar available slots
          selectedCalendarId: Number(acuityData.englishCalendarID),
        });
      }
      // Available Slots -> Kannada Language
      if (userDetail.language[0].display === "Kannada") {
        const response = await doValidateAvailableSlotsAcuity(
          Number(acuityData.appointmentTypeID),
          Number(acuityData.kannadaCalendarID)
        );
        // Move to result screen
        if(response.data) this.handleNextStep(4);

        this.setState({
          calendarData: response.data.slice(0, 3), // Pick first 3 calendar available slots
          selectedCalendarId: Number(acuityData.kannadaCalendarID),
        });
      }
      // Available Slots -> Tamil Language
      if (userDetail.language[0].display === "Tamil") {
        const response = await doValidateAvailableSlotsAcuity(
          Number(acuityData.appointmentTypeID),
          Number(acuityData.tamilCalendarID)
        );
        // Move to result screen
        if(response.data) this.handleNextStep(4);

        this.setState({
          calendarData: response.data.slice(0, 3), // Pick first 3 calendar available slots
          selectedCalendarId: Number(acuityData.tamilCalendarID),
        });
      }
      // Available Slots -> Telugu Language
      if (userDetail.language[0].display === "Telugu") {
        const response = await doValidateAvailableSlotsAcuity(
          Number(acuityData.appointmentTypeID),
          Number(acuityData.teluguCalendarID)
        );
        // Move to result screen
        if(response.data) this.handleNextStep(4);

        this.setState({
          calendarData: response.data.slice(0, 3), // Pick first 3 calendar available slots
          selectedCalendarId: Number(acuityData.teluguCalendarID),
        });
      }
      // Available Slots -> Malayalam Language
      if (userDetail.language[0].display === "Malayalam") {
        const response = await doValidateAvailableSlotsAcuity(
          Number(acuityData.appointmentTypeID),
          Number(acuityData.malayalamCalendarID)
        );
        // Move to result screen
        if(response.data) this.handleNextStep(4);

        this.setState({
          calendarData: response.data.slice(0, 3), // Pick first 3 calendar available slots
          selectedCalendarId: Number(acuityData.malayalamCalendarID),
        });
      }
    } catch (error) {
      console.log(
        "Error in metabolism calculator handleAvaillableAcuitySlots func:-",
        error
      );
    }
  };

  // Acuity Function:- To book slots in acuity
  handleBookAcuityCalendarSlot = async data => {
    try {
      const {
        firstNameUser,
        lastNameUser,
        userDetail,
        acuityData,
        selectedCalendarId,
      } = this.state;

      // Loader -> Enable
      this.setState({ loading: true });

      // Acuity Body
      const acuitySlotBody = {
        appointmentTypeID: Number(acuityData.appointmentTypeID),
        calendarID: selectedCalendarId,
        datetime: data,
        firstName: firstNameUser,
        lastName: lastNameUser ? lastNameUser : firstNameUser,
        email: userDetail.email,
        phone: userDetail.phone,
      };

      // LSQ Paramas
      const lsqBookSlotDateTime = convertUstDateTimeFormat2(data);
      const lsqParams = {
        FirstName: userDetail.name,
        Phone: userDetail.phone,
        EmailAddress: userDetail.email,
        mx_capture_calendly_booking_timedate: lsqBookSlotDateTime,
      };

      // Acuity Api Call
      const responseData = await doValidateBookSlotsAcuity(acuitySlotBody);
      if (responseData) {
        const dateAndTime = convertUstDateTimeFormat(
          responseData.data.appointmentDate
        );
        navigate(`/twin-landing-ty/?appointmentTime=${dateAndTime}`);
      }

      // LSQ Call
      await sendToLSQ(lsqParams);

      // Loader -> Disable
      this.setState({ loading: false });
    } catch (error) {
      console.log("Error in handleBookAcuityCalendarSlot func:-", error);
      // Loader -> Disable
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      firstNameUser,
      step,
      finalQuizScore,
      userDetail,
      userQuizResult,
      currentMonth,
      currentYear,
      bmiScore,
      calendarData,
      loading,
    } = this.state;

    const { utm_source } = queryString.parse(this.props.location.search);

    return (
      <div>
        <Seo
          title="Diabetes Risk Assessment Quiz | Twin"
          description="The quiz will help you in taking action against diabetes."
        />

        <HeaderWithLogo logoPosition="center" />

        {step === 0 && <AssessmentHome handleNextStep={this.handleNextStep} />}

        {step === 1 && (
          <AssessmentQuiz
            handleNextStep={this.handleNextStep}
            handleCalculateQuizScore={this.handleCalculateQuizScore}
          />
        )}

        {step === 2 && (
          <AssessmentForm
            handleNextStep={this.handleNextStep}
            handleUserDetails={this.handleUserDetails}
            userQuizResult={userQuizResult}
            utm_source={utm_source}
            finalQuizScore={finalQuizScore}
            bmiScore={bmiScore}
          />
        )}

        {/* {step === 3 && (
          <div className="row bgDarkBlue" style={{ minHeight: "92vh" }}>
            <div className="col-12 mt-3 text-center">
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_mkzxw4th.json"
                background="transparent"
                speed="1"
                style={{
                  width: "200px",
                  height: "200px",
                  margin: "auto",
                }}
                loop
                // controls
                autoplay
              ></lottie-player>

              <p style={{ fontSize: "32px", color: "#FFF" }}>
                We are calculating your metabolic score...
              </p>
            </div>
          </div>
        )} */}

        {step === 3 && (
          <>
            <AssessmentResult
              handleNextStep={this.handleNextStep}
              calendlyUrl={`https://calendly.com/twin-reversal-webinar/one-on-one-free-counselling-with-sr-health-counselor?month=${currentYear}-${currentMonth}&name=${firstNameUser}&email=${userDetail.email}`}
              finalQuizScore={finalQuizScore}
              userDetail={userDetail}
              userQuizResult={userQuizResult}
              calendarData={calendarData}
              handleBookAcuityCalendarSlot={this.handleBookAcuityCalendarSlot}
              loading={loading}
            />
          </>
        )}
      </div>
    );
  }
}

const WithContext = props => {
  const quizPayloadData = useQuizQuery("metabolismCalculator");

  const finalQuizPayload = {
    quiz_payload: quizPayloadData,
  };

  return (
    <MetabolismAssessmentPage {...props} metabolismQuiz={finalQuizPayload} />
  );
};

export default WithContext;
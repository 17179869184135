import React from "react";

import { sendToCT } from "../../services/Clevertap";

//css
import * as styles from "./Assessment.module.css";

const AssessmentHome = props => {
  const handleButtonClick = () => {
    sendToCT("metabolism_assessment_initiated", {
      action: "on clicking getting started",
    });

    props.handleNextStep(1);
  };

  return (
    <div className={`bgDarkBlue`}>
      <div className={`container ${styles.assessHome__container}`}>
        <div className={`row ${styles.conHeight2}`}>
          <div className={`col-12 ${styles.assessHome__hdng}`}>
            <h3>Get EXPERT SOLUTION</h3>
            <h3>for all your DIABETES PROBLEMS</h3>
          </div>
          <div className={`col-12 ${styles.assessHome__para}`}>
            <p>
              Assess your current metabolic health in{" "}
              <strong>just one minute</strong> with this test and know how Twin
              Health can help you in getting over your diabetes-related worries
            </p>
          </div>
          <div className={`col-12 ${styles.assessHome__cta}`}>
            <button onClick={handleButtonClick}>Start Assessment</button>
          </div>
          <div className={`col-12 ${styles.assessHome__ctaPara}`}>
            <p>It will take just one minute</p>
          </div>
          <div className={`col-12`}>
            <div className={`col-12 ${styles.assessHome__footerLine}`}></div>
            <div className={`col-12 ${styles.assessHome__footerPara}`}>
              <p>
                Please note that the following test is intended for
                informational purposes only and is not intended to be a
                substitute for medical advice. It is important to consult with a
                qualified healthcare provider to determine if you have any
                chronic metabolic condition.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentHome;

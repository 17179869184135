import React, { useState, useEffect } from "react";

// images
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./Assessment.module.css";

const ResultProgressBar = props => {
  const [slideValueDesktop, setSlideValueDesktop] = useState(0);
  const [slideValueMob, setSlideValueMob] = useState(0);
  const [slideValueBigMob, setSlideValueBigMob] = useState(0);

  useEffect(() => {
    // Score = 20(146px), 40(292px), 60(488px), 60-70(547px), 69(562px), 71-72(584px),70-80(632px), 80-85(684px), 85-90(718px), 91-92(752px), 95(781px), 95+(804px)

    if (props.finalQuizScore < 20) {
      setSlideValueDesktop(100);
      setSlideValueMob(32);
    } else if (props.finalQuizScore >= 20 && props.finalQuizScore < 30) {
      setSlideValueDesktop(220);
      setSlideValueMob(64);
      setSlideValueBigMob(68);
    } else if (props.finalQuizScore >= 30 && props.finalQuizScore < 40) {
      setSlideValueDesktop(318);
      setSlideValueMob(102);
      setSlideValueBigMob(96);
    } else if (props.finalQuizScore >= 40 && props.finalQuizScore < 50) {
      setSlideValueDesktop(410);
      setSlideValueMob(142);
      setSlideValueBigMob(125);
    } else if (props.finalQuizScore >= 50 && props.finalQuizScore < 60) {
      setSlideValueDesktop(474);
      setSlideValueMob(172);
      setSlideValueBigMob(164);
    } else if (props.finalQuizScore >= 60 && props.finalQuizScore < 65) {
      setSlideValueDesktop(520);
      setSlideValueMob(182);
      setSlideValueBigMob(196);
    } else if (props.finalQuizScore >= 65 && props.finalQuizScore < 70) {
      setSlideValueDesktop(572);
      setSlideValueMob(193);
      setSlideValueBigMob(226);
    } else if (props.finalQuizScore >= 70 && props.finalQuizScore < 73) {
      setSlideValueDesktop(624);
      setSlideValueMob(226);
      setSlideValueBigMob(255);
    } else if (props.finalQuizScore >= 73 && props.finalQuizScore < 80) {
      setSlideValueDesktop(660);
      setSlideValueMob(236);
      setSlideValueBigMob(271);
    } else if (props.finalQuizScore >= 80 && props.finalQuizScore < 85) {
      setSlideValueDesktop(700);
      setSlideValueMob(248);
      setSlideValueBigMob(286);
    } else if (props.finalQuizScore >= 85 && props.finalQuizScore < 90) {
      setSlideValueDesktop(748);
      setSlideValueMob(260);
      setSlideValueBigMob(298);
    } else if (props.finalQuizScore >= 90 && props.finalQuizScore < 93) {
      setSlideValueDesktop(796);
      setSlideValueMob(285);
      setSlideValueBigMob(322);
    } else if (props.finalQuizScore >= 93 && props.finalQuizScore < 95) {
      setSlideValueDesktop(814);
      setSlideValueMob(292);
      setSlideValueBigMob(326);
    } else if (props.finalQuizScore >= 95 && props.finalQuizScore < 100) {
      setSlideValueDesktop(834);
      setSlideValueMob(292);
      setSlideValueBigMob(332);
    } else {
      setSlideValueDesktop(846);
      setSlideValueMob(298);
      setSlideValueBigMob(338);
    }
  }, [props.finalQuizScore]);

  return (
    <div className="align-items-baseline">
      <div>
        <div className={`progress ${styles.progressBar}`}>
          <div
            class="progress-bar"
            role="progressbar"
            style={{ width: "70%", background: "#D03131" }}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-label="progress"
          ></div>
          <div
            class="progress-bar"
            role="progressbar"
            style={{ width: "20%", background: "#F37920" }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-label="progress"
          ></div>
          <div
            class="progress-bar"
            role="progressbar"
            style={{ width: "10%", background: "#50AA3E" }}
            aria-valuenow="20"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-label="progress"
          ></div>
        </div>
      </div>
      <div
        className={`${styles.progressBar__scorePara}`}
        style={{ paddingLeft: `${slideValueDesktop}px` }}
      >
        <StaticImage
          src="../../images/quiz/quiz_progress.png"
          alt="quiz_progress"
          className="img-fluid"
          placeholder="blurred"
          quality={90}
        />
        <p>{props.finalQuizScore}</p>
      </div>
      <div
        className={`${styles.progressBar__scoreParaMob}`}
        style={{ paddingLeft: `${slideValueMob}px` }}
      >
        <StaticImage
          src="../../images/quiz/quiz_progress.png"
          alt="quiz_progress"
          className="img-fluid"
          placeholder="blurred"
          quality={90}
        />
        <p>{props.finalQuizScore}</p>
      </div>
      <div
        className={`${styles.progressBar__scoreParaMob__bigScreen}`}
        style={{ paddingLeft: `${slideValueBigMob}px` }}
      >
        <StaticImage
          src="../../images/quiz/quiz_progress.png"
          alt="quiz_progress"
          className="img-fluid"
          placeholder="blurred"
          quality={90}
        />
        <p>{props.finalQuizScore}</p>
      </div>
    </div>
  );
};

export default ResultProgressBar;

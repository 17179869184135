import { apiCalls } from "./apiCalls";

// config
import config from "../config";

// Function:- Return available slots for creating an appointment in acuity
export const doValidateAvailableSlotsAcuity = async (
  appointmentTypeID,
  calendarID
) => {
  const url =
    config.DX_API_BASE_URL +
    `/api/acuity/schedule?appointmentTypeID=${appointmentTypeID}&calendarID=${calendarID}`;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Email Id Doctor Referral, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// Function:- Book an appointment in acuity
export const doValidateBookSlotsAcuity = async acuityBody => {
  const url = config.DX_API_BASE_URL + "/api/acuity/create/appointment";

  try {
    const result = await apiCalls("post", url, acuityBody);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Bookpack payment, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

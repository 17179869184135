import React, { useState } from "react";
import { navigate } from "gatsby";
import Loader from "react-loader-spinner";

//constants
import constants from "../../constants";

// component
import ResultProgressBar from "./ResultProgressBar";

// css
import * as styles from "./Assessment.module.css";

const AssessmentResult = props => {
  const [loading, setLoading] = useState(false);

  const handleBookConsultationCta = () => {
    setLoading(true);
    navigate(props.calendlyUrl);
    setLoading(false);
  };

  return (
    <div className={`bgDarkBlue ${styles.conHeight3}`}>
      <div className={`container twinContainer ${styles.assessResult__cont}`}>
        <div className={`row`}>
          <div className="col-12">
            <div>
              {/* Heading */}
              <div className="row">
                <div className={`col-12 ${styles.assessResult__hdng}`}>
                  <h3>Hi, {props.userDetail.name}</h3>
                </div>
              </div>
              {/* Progress Bar */}
              <div className="row">
                <div className={`col-12 ${styles.assessResult__progressBar}`}>
                  <ResultProgressBar finalQuizScore={props.finalQuizScore} />
                </div>
              </div>
              {/* Sub-Heading */}

              {props.finalQuizScore < 70 ? (
                <div className="row">
                  <div className={`col-12 ${styles.assessResult__subHdng}`}>
                    <p>
                      {constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA.heading1}
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  {props.finalQuizScore > 90 ? (
                    <div className="row">
                      <div className={`col-12 ${styles.assessResult__subHdng}`}>
                        <p>
                          {
                            constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                              .heading3
                          }
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className={`col-12 ${styles.assessResult__subHdng}`}>
                        <p>
                          {
                            constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                              .heading2
                          }
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* Paragraphs */}
              <div className="row">
                <div className={`col-12`}>
                  {props.finalQuizScore < 70 ? (
                    <div className={styles.assessResult__paras}>
                      <p>
                        {
                          constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                            .subHeading1
                        }
                      </p>
                    </div>
                  ) : (
                    <div>
                      {props.finalQuizScore > 90 ? (
                        <div className={styles.assessResult__paras}>
                          <p>
                            {
                              constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                                .subHeading3
                            }
                          </p>
                        </div>
                      ) : (
                        <div className={styles.assessResult__paras}>
                          <p>
                            {
                              constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                                .subHeading2
                            }
                          </p>
                        </div>
                      )}
                    </div>
                  )}

                  {props.finalQuizScore < 90 ? (
                    <div className={styles.assessResult__paras}>
                      <p>
                        {constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA.para1}
                      </p>
                    </div>
                  ) : null}
                  <div className={styles.assessResult__paras}>
                    <p>
                      Curious about how we can help? Click on{" "}
                      <strong>'Book FREE Consultation'</strong> below to
                      schedule a free consultation and discover the ways we can
                      support you.
                    </p>
                  </div>
                </div>
              </div>

              {/* CTA */}
              <div className="row">
                <div
                  className={`col-12 text-center ${styles.assessResult__cta}`}
                  onClick={handleBookConsultationCta}
                  role="presentation"
                >
                  <button>
                    <span
                      className={loading ? styles.langModal__cta__txt : null}
                    >
                      Book Free Consultation
                    </span>
                    <span className={styles.langModal__cta__loader}>
                      <Loader
                        type="TailSpin"
                        color="#FFF"
                        height={30}
                        width={30}
                        radius={1}
                        visible={loading}
                      />
                    </span>
                  </button>
                  <div className={styles.assessResult__footerCtaPara}>
                    <p>
                      *By clicking 'Book Free Consultation' you agree to be
                      contacted by Twin Health via Call, SMS, WhatsApp & Email.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div className="row">
              <div className={`col-12`}>
                <div className={`${styles.assessHome__footerLine}`}></div>
                <div className={`mb-3 ${styles.assessHome__footerPara}`}>
                  <p>
                    Please note that the following test is intended for
                    informational purposes only and is not intended to be a
                    substitute for medical advice. It is important to consult
                    with a qualified healthcare provider to determine if you
                    have any chronic metabolic condition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentResult;
